
  import { defineComponent, onMounted, ref } from 'vue';
  import AttachmentCard from '@/components/cards/AttachmentsCard.vue';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useRoute } from 'vue-router';
  import { useAbility } from '@casl/vue';

  export default defineComponent({
    name: 'appraiser-appraisal-overview',
    components: {
      AttachmentCard,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const route = useRoute();
      const { can } = useAbility();
      const appraisal = ref({});

      const translate = (text, options = {}) => {
        if (te(text)) {
          return t(text, options);
        } else {
          return text;
        }
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('appraisalRequestOverview'), [
          translate('appraisals'),
        ]);
      });

      const { data } = await store.dispatch(Actions.GET_APPRAISAL, {
        id: route.params.id,
        isAppraiser: true,
      });

      const getBadgeClass = (val) => {
        let status = 'badge-light-success';
        if (!val) {
          status = 'badge-light-danger';
        }
        return status;
      };
      appraisal.value = data.data;

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        appraisal,
        translate,
        can,
        getBadgeClass,
      };
    },
  });
